import {InventoryItem} from '../interfaces/Interfaces'

export function getQuarter(date: Date): number {
  return Math.floor((date.getMonth() + 3) / 3)
}

export function getQuartersSinceYear0(date: Date): number {
  const years = Number(date.getFullYear())
  let quarters = years * 4
  return quarters + Math.floor((date.getMonth() + 3) / 3)
}

export function getItemPrice(inventoryItem: InventoryItem): number {
  if (!inventoryItem.data) return 0

  return Object.values(inventoryItem.data.dimensions).reduce((accu, dimension) => {
    if (dimension.stock) {
      accu +=
        (inventoryItem.data.retailPrice / inventoryItem.data.priceUnitQuantity) *
        dimension.quantity *
        dimension.stock
    }
    return accu
  }, 0)
}

export function getQuantity(inventoryItem: InventoryItem): number {
  if (!inventoryItem.data) return 0

  return Object.values(inventoryItem.data.dimensions).reduce((accu, dimension) => {
    if (dimension.stock) {
      accu += dimension.quantity * dimension.stock
    }
    return accu
  }, 0)
}
