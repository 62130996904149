import * as React from 'react'
import {injectIntl, InjectedIntlProps} from 'react-intl'
import {
  InventoryItem,
  InventoryItemData,
  InventorySearchInput,
  RootState,
} from 'interfaces/Interfaces'
import {StateAction} from 'vo/RootStateReducer'
import {RouterLocationExtended} from 'control/CmsRouter'
import ModProductItem from 'components/item/ModProductItem'
import ModPagination from 'components/item/ModPagination'

const itemsPerPage = 50

export interface ModProductsProps extends InjectedIntlProps {
  context: RootState
  currentLanguage: string
  intl: any
  location: RouterLocationExtended
  searchInput: InventorySearchInput
  setRootState: (action: StateAction, payload?: any) => void
}

interface ModProductState {
  nameSearchInput: string
  idCosanumSearchInput: string
  nameProducerSearchInput: string
  scopeSearch: string
  artGroup: string
  onSave: boolean
  filteredItems: number[]
  paginationPage: number
}

class ModProducts extends React.Component<ModProductsProps, ModProductState> {
  constructor(props) {
    super(props)

    const {searchInput, context} = this.props

    this.state = {
      nameSearchInput: searchInput.isFiltered ? searchInput.nameSearchInput : '',
      nameProducerSearchInput: searchInput.isFiltered ? searchInput.nameProducerSearchInput : '',
      idCosanumSearchInput: searchInput.isFiltered ? searchInput.idCosanumSearchInput : '',
      scopeSearch: searchInput.isFiltered ? searchInput.scopeSearch : '',
      artGroup: '',
      onSave: false,
      filteredItems: searchInput.isFiltered
        ? searchInput.filteredItems
        : context.inventory.list.map((item, index) => index),
      paginationPage: 0,
    }

    this.handleQuantityChange = this.handleQuantityChange.bind(this)
    this.handleSearchInput = this.handleSearchInput.bind(this)
    this.toPage = this.toPage.bind(this)
  }

  toPage(index: number) {
    this.setState({
      paginationPage: index,
    })
  }

  handleSearchInput(event, searchId) {
    const {context} = this.props

    let searchInput: any = String(event.target.value)

    let nameSearchInput = this.state.nameSearchInput
    let idCosanumSearchInput = this.state.idCosanumSearchInput
    let nameProducerInput = this.state.nameProducerSearchInput
    let scopeSearch = this.state.scopeSearch

    switch (searchId) {
      case 'name':
        nameSearchInput = searchInput.toLowerCase()
        break
      case 'idCosanum':
        idCosanumSearchInput = searchInput.toLowerCase()
        break
      case 'nameProducer':
        nameProducerInput = searchInput.toLowerCase()
        break
      case 'scope':
        scopeSearch = searchInput
        break
    }

    let filteredList = context.inventory.list.reduce((acc, item, index) => {
      let articleId = context.articles[item.article[1]]
      let currentLang = this.props.currentLanguage
      let idCosanum = articleId.id
      let nameProducer =
        context.manufacturer[context.articles[item.article[1]].manufacturer[1]].name.toLowerCase()
      let scopeName =
        context.applicationArea[context.articles[item.article[1]].applicationArea[1]].i18n[
          this.props.currentLanguage
        ].name

      let description
      if (articleId.i18n[currentLang] == undefined) {
        description = ''
      } else {
        description = articleId.i18n[currentLang].description.toLowerCase()
      }

      if (
        description.indexOf(nameSearchInput) != -1 &&
        idCosanum.indexOf(idCosanumSearchInput) != -1 &&
        nameProducer.indexOf(nameProducerInput) != -1 &&
        (scopeSearch ? scopeName.indexOf(scopeSearch) != -1 : true)
      ) {
        acc.push(index)
      }

      return acc
    }, [] as number[])

    this.setState({
      filteredItems: filteredList,
      nameSearchInput: nameSearchInput,
      idCosanumSearchInput: idCosanumSearchInput,
      nameProducerSearchInput: nameProducerInput,
      scopeSearch: scopeSearch,
    })

    this.props.setRootState(StateAction.searchInput, {
      filteredItems: filteredList,
      isFiltered: true,
      nameSearchInput: nameSearchInput,
      idCosanumSearchInput: idCosanumSearchInput,
      nameProducerSearchInput: nameProducerInput,
      scopeSearch: scopeSearch,
    })
  }

  handleQuantityChange(inventoryIndex: number, dimensionIndex: number, event) {
    const {context} = this.props
    const inventoryList = context.inventory.list
    const inventoryItem: InventoryItem = inventoryList[inventoryIndex]
    const article = context.articles[inventoryItem.article[1]]
    const price = context.prices.priceList[inventoryItem.articleId]

    if (!inventoryList[inventoryIndex].data) {
      inventoryList[inventoryIndex].data = {
        dimensions: article.dimensions,
        priceUnitQuantity: article.priceUnitQuantity,
        retailPrice: price.retailPrice,
      }
    }

    inventoryList[inventoryIndex].data.dimensions[dimensionIndex].stock =
      Math.floor(Number(event.target.value)) || 0

    this.props.setRootState(StateAction.updateInventory, context)
  }

  componentDidMount() {
    this.props.setRootState(StateAction.removeLoadingIndex)
  }

  render() {
    const {paginationPage} = this.state
    const {context, currentLanguage} = this.props
    const {formatMessage} = this.props.intl

    const inventory = context.inventory
    const productMap = inventory.list

    let scopeArray = []
    productMap.map((item) => {
      let scope =
        context.applicationArea[context.articles[item.article[1]].applicationArea[1]].i18n[
          currentLanguage
        ].name
      if (!scopeArray.includes(scope)) {
        scopeArray.push(scope)
      }
    })

    let filterFields = (
      <div className="filter-fields">
        <input
          className="search-field"
          type="search"
          spellCheck={false}
          placeholder={formatMessage({
            id: 'filter.searchProducer',
            defaultMessage: 'Search Producer',
          })}
          onChange={(event) => this.handleSearchInput(event, 'nameProducer')}
          value={this.state.nameProducerSearchInput}
        />
        <input
          className="search-field"
          type="search"
          spellCheck={false}
          placeholder={formatMessage({
            id: 'filter.searchProductname',
            defaultMessage: 'Search Productname',
          })}
          onChange={(event) => this.handleSearchInput(event, 'name')}
          value={this.state.nameSearchInput}
        />
        <input
          className="search-field"
          type="search"
          spellCheck={false}
          placeholder={formatMessage({id: 'filter.artNoCosanum', defaultMessage: 'ArtNo Cosanum'})}
          onChange={(event) => this.handleSearchInput(event, 'idCosanum')}
          value={this.state.idCosanumSearchInput}
        />

        <div className="filter-select select">
          <select
            onChange={(event) => this.handleSearchInput(event, 'scope')}
            value={this.state.scopeSearch}>
            <option value="">
              {formatMessage({id: 'filter.searchAll', defaultMessage: 'All'})}
            </option>

            {scopeArray.sort().map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
          <div className="select_arrow" />
        </div>
      </div>
    )

    const startIndex = paginationPage * itemsPerPage
    let product = this.state.filteredItems
      .slice(startIndex, startIndex + itemsPerPage)
      .map((id: number) => {
        const item = context.inventory.list[id]
        const price = context.prices.priceList[item.articleId]
        const article = context.articles[item.article[1]]

        if (price && article) {
          let dimensionData: InventoryItemData = null
          if (item.data) {
            dimensionData = item.data
          } else {
            dimensionData = {
              dimensions: article.dimensions,
              priceUnitQuantity: article.priceUnitQuantity,
              retailPrice: price.retailPrice,
            }
          }

          return (
            <ModProductItem
              key={id}
              id={id}
              item={item}
              article={article}
              currentLanguage={currentLanguage}
              dimensionData={dimensionData}
              manufacturer={context.manufacturer[article.manufacturer[1]]}
              inventory={inventory}
              handleQuantityChange={this.handleQuantityChange}
            />
          )
        }
      })

    const pages = Math.ceil(this.state.filteredItems.length / itemsPerPage)

    return (
      <div>
        {filterFields}
        <div className="inventory-list">{product}</div>
        <ModPagination currentPage={paginationPage} pages={pages} toPage={this.toPage} />
      </div>
    )
  }
}

export default injectIntl(ModProducts)
