import * as React from 'react'
import {FormattedMessage, injectIntl, InjectedIntlProps} from 'react-intl'
import {StateAction} from 'vo/RootStateReducer'
import {
  getClientReportDetailByFormat,
  getClientReportApplicationByFormat,
  getClientReportClosedByFormat,
  getClientReportDetail,
  getClientReportApplication,
  getClientReportClosed,
  getAvailableClientReports,
  getClientReportMigel,
  getClientReportMigelByFormat,
} from 'frontend/ApiClient'
import NavLink from 'components/common/NavLink'
import {Routes} from 'frontend/Routes'
import UserSession from 'vo/UserSession'
import {Availableinventories} from 'interfaces/Interfaces'

export interface ModReportsProps extends InjectedIntlProps {
  userSession: UserSession
  currentLanguage: string
  setRootState: (action: StateAction, payload?: any) => void
  intl: any
}

export interface ModReportsState {
  reportFormat: string
  noFormatSelected: boolean
  availableinventories: Availableinventories[]
  quarterSinceYear0: number
}

class ModReports extends React.Component<ModReportsProps, ModReportsState> {
  constructor(props) {
    super(props)

    this.state = {
      reportFormat: '',
      noFormatSelected: false,
      availableinventories: [],
      quarterSinceYear0: 0,
    }

    this.getReportFormat = this.getReportFormat.bind(this)
    this.getQuarter = this.getQuarter.bind(this)
    this.downloadReport = this.downloadReport.bind(this)
    this.preview = this.preview.bind(this)
    this.getAvailableClientReports = this.getAvailableClientReports.bind(this)
  }

  getReportFormat(event) {
    this.setState({
      reportFormat: event.target.value,
    })
  }

  getQuarter(event) {
    this.setState({
      quarterSinceYear0: event.target.value,
    })
  }

  async getAvailableClientReports() {
    const r = await getAvailableClientReports()
    this.setState({
      availableinventories: r,
      quarterSinceYear0: r[0].quartersSinceYear0,
    })
  }

  async downloadReport(type: String) {
    const {reportFormat, quarterSinceYear0} = this.state
    const lang = this.props.currentLanguage

    if (reportFormat != '') {
      if (type == 'detailedReport') {
        let response = await getClientReportDetailByFormat(lang, quarterSinceYear0, reportFormat)
        window.open(response)
      } else if (type == 'scopeReport') {
        let response = await getClientReportApplicationByFormat(
          lang,
          quarterSinceYear0,
          reportFormat
        )
        window.open(response)
      } else if (type == 'closedReport') {
        let response = await getClientReportClosedByFormat(lang, quarterSinceYear0, reportFormat)
        window.open(response)
      } else if (type == 'migelReport') {
        let response = await getClientReportMigelByFormat(lang, quarterSinceYear0, reportFormat)
        window.open(response)
      }
    } else {
      this.setState({
        noFormatSelected: true,
      })

      setTimeout(() => {
        this.setState({
          noFormatSelected: false,
        })
      }, 2000)
    }
  }

  async preview(type: String) {
    const {reportFormat, quarterSinceYear0} = this.state
    const lang = this.props.currentLanguage

    this.props.setRootState(StateAction.addLoadingIndex)

    if (type == 'detailedReport') {
      let response = await getClientReportDetail(lang, quarterSinceYear0)
      this.props.setRootState(StateAction.reportType, 'detailedReport')
      this.props.setRootState(StateAction.updateBranchReport, response)
    } else if (type == 'scopeReport') {
      let response = await getClientReportApplication(lang, quarterSinceYear0)
      this.props.setRootState(StateAction.reportType, 'scopeReport')
      this.props.setRootState(StateAction.updateBranchReport, response)
    } else if (type == 'closedReport') {
      let response = await getClientReportClosed(lang, quarterSinceYear0)
      this.props.setRootState(StateAction.reportType, 'closedReport')
      this.props.setRootState(StateAction.updateBranchReport, response)
    } else if (type == 'migelReport') {
      let response = await getClientReportMigel(lang, quarterSinceYear0)
      this.props.setRootState(StateAction.reportType, 'migelReport')
      this.props.setRootState(StateAction.updateBranchReport, response)
    }
  }

  componentDidMount() {
    this.props.setRootState(StateAction.removeLoadingIndex)
    this.props.setRootState(StateAction.languageVisible, true)
    this.getAvailableClientReports().catch(console.error)
  }

  render() {
    const {reportFormat, noFormatSelected, availableinventories, quarterSinceYear0} = this.state
    const {formatMessage} = this.props.intl
    const {currentLanguage} = this.props

    let message
    if (noFormatSelected) {
      message = (
        <div>
          <FormattedMessage id="inventor.noFormatSelected" defaultMessage="please select format" />
        </div>
      )
    }

    let markupAvailableinventories = null
    if (availableinventories) {
      markupAvailableinventories = (
        <div className="select">
          <select onChange={this.getQuarter} value={quarterSinceYear0}>
            {Object.values(availableinventories).map((value) => {
              return (
                <option value={value.quartersSinceYear0} key={value.quartersSinceYear0}>
                  {value.year} {value.quarter}
                </option>
              )
            })}
          </select>
          <div className="select_arrow" />
        </div>
      )
    }

    let downloadREportMarkup = null
    if (quarterSinceYear0) {
      downloadREportMarkup = (
        <>
          <div className="report-download">
            <div className="report-text">
              <FormattedMessage id="report.detailedReport" defaultMessage="Report Detail" />
            </div>

            <div className="button-wrapper">
              <button
                type="button"
                className="download-report"
                onClick={this.downloadReport.bind(this, 'detailedReport')}>
                <FormattedMessage id="report.download" defaultMessage="Download" />
              </button>

              <NavLink
                to={`${currentLanguage}${Routes.client}${Routes.preview}`}
                onClick={this.preview.bind(this, 'detailedReport')}>
                <div className="button-link">
                  <FormattedMessage id="report.preview" defaultMessage="Preview" />
                </div>
              </NavLink>
            </div>
          </div>

          <div className="report-download">
            <div className="report-text">
              <FormattedMessage
                id="report.scopeReport"
                defaultMessage="Report by Application Area"
              />
            </div>

            <div className="button-wrapper">
              <button
                type="button"
                className="last-button"
                onClick={this.downloadReport.bind(this, 'scopeReport')}>
                <FormattedMessage id="report.download" defaultMessage="Download" />
              </button>

              <NavLink
                to={`${currentLanguage}${Routes.client}${Routes.preview}`}
                onClick={this.preview.bind(this, 'scopeReport')}>
                <div className="button-link">
                  <FormattedMessage id="report.preview" defaultMessage="Preview" />
                </div>
              </NavLink>
            </div>
          </div>

          <div className="report-download">
            <div className="report-text">
              <FormattedMessage
                id="report.closedReport"
                defaultMessage="Report Value per Address & Status"
              />
            </div>

            <div className="button-wrapper">
              <button
                type="button"
                className="download-report"
                onClick={this.downloadReport.bind(this, 'closedReport')}>
                <FormattedMessage id="report.download" defaultMessage="Download" />
              </button>

              <NavLink
                to={`${currentLanguage}${Routes.client}${Routes.preview}`}
                onClick={this.preview.bind(this, 'closedReport')}>
                <div className="button-link">
                  <FormattedMessage id="report.preview" defaultMessage="Preview" />
                </div>
              </NavLink>
            </div>
          </div>

          <div className="report-download">
            <div className="report-text">
              <FormattedMessage id="report.migelReport" defaultMessage="Report migel" />
            </div>

            <div className="button-wrapper">
              <button
                type="button"
                className="last-button"
                onClick={this.downloadReport.bind(this, 'migelReport')}>
                <FormattedMessage id="report.download" defaultMessage="Download" />
              </button>

              <NavLink
                to={`${currentLanguage}${Routes.client}${Routes.preview}`}
                onClick={this.preview.bind(this, 'migelReport')}>
                <div className="button-link">
                  <FormattedMessage id="report.preview" defaultMessage="Preview" />
                </div>
              </NavLink>
            </div>
          </div>
        </>
      )
    }

    return (
      <div className="report-wrapper">
        <div className="title">
          <strong>
            <FormattedMessage id="report.title" defaultMessage="Reports" />
            &nbsp;
            {this.props.userSession.hasSession ? this.props.userSession.user.name : ''}
          </strong>
        </div>

        <div className="report-content">
          <div className="report-format-wrapper">
            <div className="report-format">{markupAvailableinventories}</div>
          </div>
          <div className="report-format-wrapper">
            <div className="report-format">
              <div className="select">
                <select onChange={this.getReportFormat} value={reportFormat}>
                  <option value="" disabled>
                    {formatMessage({
                      id: 'branch.chooseFormat',
                      defaultMessage: 'Choose format',
                    })}
                  </option>
                  <option value="xlsx">.xlsx</option>
                  <option value="biff8">.xls</option>
                  <option value="html">.html</option>
                  <option value="csv">.csv</option>
                </select>
                <div className="select_arrow" />
              </div>
            </div>

            <div className="update-message">{message}</div>
          </div>
          {downloadREportMarkup}
        </div>
      </div>
    )
  }
}

export default injectIntl<ModReportsProps>(ModReports)
