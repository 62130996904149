import * as React from 'react'
import {injectIntl} from 'react-intl'
import {StateAction} from 'vo/RootStateReducer'
import {BranchReport} from 'interfaces/Interfaces'
import ReportTableView from 'components/report/ReportTableView'
import NavLink from 'components/common/NavLink'
import {Routes} from 'frontend/Routes'
import UserSession from 'vo/UserSession'

export interface ModReportPreviewProps {
  branchReport: BranchReport
  currentLanguage: string
  setRootState: (action: StateAction, payload?: any) => void
  reportType: string
  intl: any
  userSession: UserSession
}

class ModReportPreview extends React.Component<ModReportPreviewProps, {}> {
  constructor(props) {
    super(props)

    this.state = {}

    this.print = this.print.bind(this)
  }

  print() {
    window.print()
  }

  componentDidMount() {
    this.props.setRootState(StateAction.removeLoadingIndex)
    this.props.setRootState(StateAction.languageVisible, false)
  }

  render() {
    const {branchReport, reportType, currentLanguage, userSession} = this.props
    const {formatMessage} = this.props.intl

    if (!userSession.hasSession) {
      return null
    }

    let reportList = <ReportTableView branchReport={branchReport} />
    let reportTitle = formatMessage({id: 'report.' + reportType, defaultMessage: reportType})

    return (
      <div className="preview-wrapper">
        <div className="printview-header">
          <div className="title">
            <div className="title-semibold">{reportTitle}&nbsp;</div>-{' '}
            {this.props.userSession.user.name}
          </div>

          <div className="print-content-right">
            <div className="print" onClick={this.print}>
              <img src={require('static/img/svg/print.svg')} />
            </div>
            <NavLink to={`${currentLanguage}${Routes.client}`}>
              <div className="back">x</div>
            </NavLink>
          </div>
        </div>

        {reportList}
      </div>
    )
  }
}

export default injectIntl<ModReportPreviewProps>(ModReportPreview)
