import * as React from 'react'
import {
  RootState,
  InventoryItem,
  InventoryItemData,
  InventorySearchInput,
} from 'interfaces/Interfaces'
import {FormattedMessage, injectIntl} from 'react-intl'
import {StateAction} from 'vo/RootStateReducer'
import NavLink from 'components/common/NavLink'
import {Routes} from 'frontend/Routes'

export interface ModPrintViewProps {
  context: RootState
  currentLanguage: string
  intl: any
  searchInput: InventorySearchInput
  setRootState: (action: StateAction, payload?: any) => void
}

interface ModPrintViewState {}

class ModPrintView extends React.Component<ModPrintViewProps, ModPrintViewState> {
  constructor(props) {
    super(props)
    this.state = {}

    this.print = this.print.bind(this)
  }

  print() {
    window.print()
  }

  async componentDidMount() {
    await this.props.setRootState(StateAction.removeLoadingIndex)
  }

  render() {
    const {context, currentLanguage, searchInput} = this.props
    const {formatMessage} = this.props.intl

    let header = (
      <div className="print-header">
        <div className="print-text">
          <div className="semibold">{context.branch.id}</div>
          &nbsp;-&nbsp;
          <FormattedMessage id="branch.inventoryList" defaultMessage="Inventorylist" />
          &nbsp;-&nbsp;
          <div className="semibold">{context.branch.name}</div>
        </div>

        <div className="print-content-right">
          <div className="print" onClick={this.print}>
            <img src={require('static/img/svg/print.svg')} />
          </div>
          <NavLink to={`${currentLanguage}${Routes.branch}/${context.inventory.id}`}>
            <div className="back">x</div>
          </NavLink>
        </div>
      </div>
    )

    let consumer = (
      <div className="customer-content">
        <div className="content-left">
          <div className="semibold">{context.branch.id}</div>
          &nbsp;-&nbsp;
          <FormattedMessage id="branch.inventoryList" defaultMessage="Inventorylist" />
          &nbsp;-&nbsp;
          <div className="semibold">{context.branch.name}</div>
        </div>

        <div className="content-right">
          <img className="cosanum-logo" src={require('static/img/cosanum.png')} />
        </div>

        <div className="content-center-left">
          <div>
            <div className="title">
              <FormattedMessage id="branch.client" defaultMessage="client" />:
            </div>
            <div className="strong">{context.branch.name}</div>
          </div>

          <div>
            <div className="title">
              <FormattedMessage id="branch.addressAddition" defaultMessage="address addition" />:
            </div>
            <div className="content address">
              <strong> {context.branch.addressAddition}</strong>
              <strong> {context.branch.address}</strong>
              <strong> {context.branch.city}</strong>
              <br />
            </div>
          </div>

          <div>
            <div className="title">
              <FormattedMessage id="branch.customerNumber" defaultMessage="customer number" />:
            </div>
            <div className="strong">{context.branch.id}</div>
          </div>

          <div className="">
            <div className="title">
              <FormattedMessage id="branch.datePeriod" defaultMessage="date period" />:
            </div>
            <div className="strong">
              {context.inventory.year} - {context.inventory.quarter}
            </div>
          </div>

          <div className="">
            <div className="title">
              <FormattedMessage id="branch.totalProducts" defaultMessage="total products" />:
            </div>
            <div className="strong">
              <strong>{context.inventory.list.length}</strong>
            </div>
          </div>
        </div>
      </div>
    )

    let productItemArray = []
    let productMap = context.inventory.list

    if (searchInput.isFiltered) {
      productMap = productMap.filter((item: InventoryItem, index: number) =>
        searchInput.filteredItems.includes(index)
      )
    }

    productMap.map((item: InventoryItem, index) => {
      const article = context.articles[item.article[1]]
      let dimensionData: InventoryItemData = null

      if (item.data) {
        dimensionData = item.data
      } else {
        dimensionData = {
          dimensions: article.dimensions,
          priceUnitQuantity: null,
          retailPrice: null,
        }
      }

      productItemArray.push(
        <div className="product-content" key={index}>
          <div className="content-left">
            {context.articles[item.article[1]].imageUrl && (
              <img className="product-image" src={context.articles[item.article[1]].imageUrl} />
            )}
          </div>

          <div className="content-center-left">
            <div className="">
              <div className="title small-width">
                <FormattedMessage id="inventor.manufacturer" defaultMessage="manufacturer" />:
              </div>
              <div className="string iventor-content">
                <strong>
                  {context.manufacturer[context.articles[item.article[1]].manufacturer[1]].name}
                </strong>
              </div>
            </div>

            <div className="">
              <div className="title small-width">
                <FormattedMessage id="inventor.articleId" defaultMessage="item no M" />:
              </div>
              <div className="string iventor-content">
                <strong>{context.articles[item.article[1]].idManufacturer}</strong>
              </div>
            </div>

            <div className="">
              <div className="title small-width">
                <FormattedMessage id="inventor.idCosanum" defaultMessage="item no C" />:
              </div>
              <div className="string iventor-content">
                <strong>{item.articleId}</strong>
              </div>
            </div>

            <div className="">
              <div className="title small-width">
                <FormattedMessage id="inventor.description" defaultMessage="description" />:
              </div>
              <div className="string iventor-content">
                <strong>
                  {context.articles[item.article[1]].i18n[this.props.currentLanguage].description}
                </strong>
              </div>
            </div>
          </div>

          <div className="content-right">
            {dimensionData.dimensions.map((dimension, dimensionIndex) => {
              if (dimension.id != 'pal') {
                return (
                  <div className="title small-width" key={dimensionIndex}>
                    <div className="description">
                      <strong>
                        {formatMessage({
                          id: 'inventor.' + dimension.id,
                          defaultMessage: dimension.description,
                        })}
                      </strong>
                    </div>
                    <div className="line" />
                  </div>
                )
              }
            })}
          </div>
        </div>
      )
    })

    let products = <div className="inventory-list">{productItemArray}</div>

    return (
      <div className="print-view-wrapper">
        {header}
        {consumer}
        {products}
      </div>
    )
  }
}

export default injectIntl(ModPrintView)
