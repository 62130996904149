export enum Browser {
  firefox = 'firefox',
  chrome = 'chrome',
  safari = 'safari',
  edge = 'edge',
  ie = 'ie',
  unknown = 'unknown',
}

declare var InstallTrigger: any
declare var isIE: any
declare var safari: any

export interface BrowserDetectionResponse {
  browser: Browser
}

export function getBrowser(): BrowserDetectionResponse {
  let result: BrowserDetectionResponse = {
    browser: Browser.unknown,
  }

  if (typeof window === 'undefined') {
    return result
  }

  const _window = window as any

  if (typeof InstallTrigger !== 'undefined') {
    result.browser = Browser.firefox
  } else if (checkForSafari(_window)) {
    result.browser = Browser.safari
  } else if (!!(document as any).documentMode) {
    result.browser = Browser.ie
  } else if (typeof isIE === 'undefined' && !!_window.StyleMedia) {
    result.browser = Browser.edge
  } else if (!!_window.chrome && !!_window.chrome.webstore) {
    result.browser = Browser.chrome
  }

  return result
}

function checkForSafari(anyWindow) {
  return (
    /constructor/i.test(anyWindow.HTMLElement) ||
    (function (p) {
      return p.toString() === '[object SafariRemoteNotification]'
    })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification))
  )
}
