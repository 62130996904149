import * as React from 'react'
import {
  closeInventory,
  updateInventoryStock,
  getBranchReportDetailByFormat,
  getBranchReportApplicationByFormat,
  reOpenInventory,
} from 'frontend/ApiClient'
import {
  FormattedMessage,
  FormattedNumber,
  injectIntl,
  InjectedIntlProps,
  IntlProvider,
} from 'react-intl'
import {RootState, InventoryItem, Inventory, Dimension} from 'interfaces/Interfaces'
import {Routes} from 'frontend/Routes'
import {RouterLocationExtended} from 'control/CmsRouter'
import {StateAction} from 'vo/RootStateReducer'
import {getItemPrice} from 'common/Utils'
import {supportedLanguagesRegExp} from 'common/Languages'
import moment = require('moment')
import NavLink from 'components/common/NavLink'
import ModApp from 'components/ModApp'

export interface ModConsumerProps extends InjectedIntlProps {
  context: RootState
  currentLanguage: string
  intl: any
  location: RouterLocationExtended
  setRootState: (action: StateAction, payload?: any) => void
}

interface ModConsumerState {
  printView: boolean
  reportFormat: string
  onSave: boolean
  onUpdate: boolean
  noFormatSelected: boolean
}

class ModConsumer extends React.Component<ModConsumerProps, ModConsumerState> {
  constructor(props) {
    super(props)

    this.state = {
      printView: false,
      reportFormat: '',
      onSave: false,
      onUpdate: false,
      noFormatSelected: false,
    }

    this.print = this.print.bind(this)
    this.closingInventory = this.closingInventory.bind(this)
    this.getReportFormat = this.getReportFormat.bind(this)
    this.downloadReport = this.downloadReport.bind(this)
    this.updateInventory = this.updateInventory.bind(this)
    this.reOpenInventory = this.reOpenInventory.bind(this)
    this.reloadInventory = this.reloadInventory.bind(this)
  }

  async print() {
    this.props.setRootState(StateAction.addLoadingIndex)
    this.updateInventory(false)
  }

  async updateInventory(showSaveMassage: boolean = true) {
    let metaInventory: Inventory = JSON.parse(JSON.stringify(this.props.context.inventory))

    metaInventory.list.forEach((inventoryItem: InventoryItem, index) => {
      if (inventoryItem.data) {
        inventoryItem.data.dimensions.forEach((dimension: Dimension) => {
          dimension.stock = dimension.stock || 0
        })
      }
    })

    try {
      await updateInventoryStock(metaInventory)

      if (showSaveMassage) {
        this.setState({
          onSave: true,
        })

        setTimeout(() => {
          this.setState({
            onSave: false,
          })
        }, 2000)
      }
    } catch (e) {
      alert(e.toString())
    }
  }

  async reOpenInventory(event) {
    const {setRootState, location} = this.props

    reOpenInventory(this.props.context.inventory.id)
      .then(async (e) => {
        await this.reloadInventory()
      })
      .catch((e) => {
        alert(e.toString())
      })
  }

  async closingInventory(event) {
    const {setRootState, location} = this.props

    closeInventory(this.props.context.inventory.id)
      .then(async (e) => {
        await this.reloadInventory()

        this.setState({
          onUpdate: true,
        })

        setTimeout(() => {
          this.setState({
            onUpdate: false,
          })
        }, 2000)
      })
      .catch((e) => {
        alert(e.toString())
      })
  }

  async reloadInventory() {
    const {setRootState, location} = this.props

    const pathName = location.pathname.replace(supportedLanguagesRegExp, '')
    const regex = /\/branch\/(.*?)(\/|$)/gi
    const id = regex.exec(pathName)

    let context = await ModApp.loadInventoryItems(id[1])
    if (id) {
      setRootState(StateAction.updateInventory, context)
    }
  }

  getReportFormat(event) {
    this.setState({
      reportFormat: event.target.value,
    })
  }

  async downloadReport(type: String) {
    const {reportFormat} = this.state
    const id = this.props.context.inventory.id
    const lang = this.props.currentLanguage

    if (reportFormat != '') {
      if (type == 'detailedReport') {
        let response = await getBranchReportDetailByFormat(id, lang, reportFormat)
        window.open(response)
      } else {
        let response = await getBranchReportApplicationByFormat(id, lang, reportFormat)
        window.open(response)
      }
    } else {
      this.setState({
        noFormatSelected: true,
      })

      setTimeout(() => {
        this.setState({
          noFormatSelected: false,
        })
      }, 2000)
    }
  }

  render() {
    const {onSave, onUpdate, noFormatSelected, reportFormat} = this.state
    const {formatMessage} = this.props.intl
    const {context, currentLanguage} = this.props

    const productMap = context.inventory.list
    const inventory = this.props.context.inventory

    //Total Price
    let totalPrice = 0
    productMap.map((item: InventoryItem) => {
      totalPrice += getItemPrice(item)
    })

    //Done Inventar
    let inventoryButton = null
    if (!inventory.completionDate) {
      inventoryButton = (
        <button type="button" className="closeInventory" onClick={this.closingInventory}>
          <FormattedMessage id="branch.closingInventory" defaultMessage="closing inventory" />
        </button>
      )
    } else {
      let formatedDate = moment(context.inventory.completionDate).format('DD.MM.YYYY')

      inventoryButton = (
        <div className="closing-inventory">
          <button type="button" className="closeInventory" onClick={this.reOpenInventory}>
            <FormattedMessage id="branch.cancelLock" defaultMessage="cancel lock" />
          </button>
          <div className="select">
            <select onChange={this.getReportFormat} value={reportFormat}>
              <option value="" disabled>
                {formatMessage({id: 'branch.chooseFormat', defaultMessage: 'Choose format'})}
              </option>
              <option value="xlsx">.xlsx</option>
              <option value="biff8">.xls</option>
              <option value="html">.html</option>
              <option value="csv">.csv</option>
            </select>
            <div className="select_arrow" />

            <button
              type="button"
              className="download-report"
              onClick={this.downloadReport.bind(this, 'detailedReport')}>
              <FormattedMessage id="branch.detailedReport" defaultMessage="Detailed report" />
            </button>

            <button
              type="button"
              className="last-button"
              onClick={this.downloadReport.bind(this, 'scopeReport')}>
              <FormattedMessage
                id="branch.reportScope"
                defaultMessage="Report Scope of Application"
              />
            </button>
          </div>
          <div className="closed-inventory-wrapper">
            <div className="msg-closed-inventory">
              <FormattedMessage
                id="branch.closedInventoryText"
                defaultMessage="This inventory was completed"
              />{' '}
              {formatedDate} <FormattedMessage id="branch.completedText" defaultMessage=" " />
            </div>
          </div>
        </div>
      )
    }

    let consumer = (
      <div className="customer-content">
        <div className="content-left">
          <div className="content-top">
            <div className="semibold">{context.branch.id}</div>
            &nbsp;-&nbsp;
            <FormattedMessage id="branch.inventoryList" defaultMessage="Inventorylist" />
            &nbsp;-&nbsp;
          </div>
          <div className="semibold">{context.branch.name}</div>
        </div>

        <div className="customer-center">
          <div className="content-center-left">
            <div>
              <div className="title">
                <FormattedMessage id="branch.client" defaultMessage="client" />:
              </div>
              <div className="content">
                <strong>{context.branch.name}</strong>
              </div>
            </div>

            <div>
              <div className="title">
                <FormattedMessage id="branch.addressAddition" defaultMessage="address addition" />:
              </div>
              <div className="content address">
                <strong> {context.branch.addressAddition}</strong>
                <strong> {context.branch.address}</strong>
                <strong> {context.branch.city}</strong>
                <br />
              </div>
            </div>

            <div>
              <div className="title">
                <FormattedMessage id="branch.customerNumber" defaultMessage="customer number" />:
              </div>
              <div className="content">
                <strong>{context.branch.id}</strong>
              </div>
            </div>

            <div className="">
              <div className="title">
                <FormattedMessage id="branch.datePeriod" defaultMessage="date period" />:
              </div>
              <div className="content">
                <strong>
                  {context.inventory.year} / {context.inventory.quarter}
                </strong>
              </div>
            </div>
          </div>

          <div className="content-center-right">
            <div className="">
              <div className="title-small">
                <FormattedMessage id="branch.totalProducts" defaultMessage="total products" />:
              </div>
              <div className="strong">
                <strong>{context.inventory.list.length}</strong>
              </div>
            </div>

            <div className="">
              <div className="title-small">
                <FormattedMessage id="branch.totalPrice" defaultMessage="total price" />:
              </div>
              <div className="strong">
                <strong>
                  <IntlProvider locale="de-CH">
                    <FormattedNumber style="currency" currency="CHF" value={totalPrice} />
                  </IntlProvider>
                </strong>
              </div>
            </div>
          </div>
        </div>

        <div className="content-right">
          <NavLink
            to={`${currentLanguage}${Routes.branch}/${context.inventory.id}${Routes.print}`}
            onClick={this.print}>
            <div className="button-link" onClick={this.print}>
              <FormattedMessage id="branch.printview" defaultMessage="printview" />
            </div>
          </NavLink>

          <button type="button" className="" onClick={() => this.updateInventory()}>
            <FormattedMessage id="branch.save" defaultMessage="save" />
          </button>

          {inventoryButton}
        </div>
      </div>
    )

    //Save and Update Message
    let message
    if (onUpdate || onSave || noFormatSelected) {
      if (onSave) {
        message = (
          <div className="update-message">
            <FormattedMessage id="inventor.onSaveMessage" defaultMessage="save successful" />
          </div>
        )
      } else if (onUpdate) {
        message = (
          <div className="update-message">
            <FormattedMessage id="inventor.onClosedMessage" defaultMessage="Inventory closed" />
          </div>
        )
      } else if (noFormatSelected) {
        message = (
          <div className="update-message">
            <FormattedMessage
              id="inventor.noFormatSelected"
              defaultMessage="please select format"
            />
          </div>
        )
      }
    }

    return (
      <div>
        {consumer}
        <div className="update-message-wrapper">{message}</div>
      </div>
    )
  }
}

export default injectIntl(ModConsumer)
