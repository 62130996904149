import {
  Branch,
  IPublicSettings,
  Translations,
  InventoryList,
  RootState,
  BranchReport,
  InventorySearchInput,
} from '../interfaces/Interfaces'
import UserSession from './UserSession'

export enum LoadingState {
  idle = 'idle',
  loading = 'loading',
  offline = 'offline',
  reload = 'reload',
}

export interface IStateController {
  contentType: any
  getContentIdByTag: Function
}

export default class implements IStateController {
  contentType: {[key: string]: string}
  loadingState: LoadingState
  translations: Translations
  userSession: UserSession
  inventoryList: InventoryList[]
  publicSettings: IPublicSettings
  context: RootState
  loadingIndex: number
  branchReport: BranchReport
  reportType: string
  //Todo do this over url
  languageVisible: boolean
  brokenSession: boolean
  searchInput: InventorySearchInput

  constructor() {
    this.contentType = null
    this.loadingState = LoadingState.idle
    this.userSession = new UserSession()
    this.getContentIdByTag = this.getContentIdByTag.bind(this)
    this.publicSettings = null
    this.context = null
    this.loadingIndex = 0
    this.branchReport = null
    this.reportType = null
    this.languageVisible = true
    this.brokenSession = false
    this.searchInput = {
      filteredItems: [],
      isFiltered: false,
      nameSearchInput: '',
      idCosanumSearchInput: '',
      nameProducerSearchInput: '',
      scopeSearch: '',
    }
  }

  getContentIdByTag(tag) {
    return this.contentType[tag]
  }
}
