import * as React from 'react'
import * as de from 'react-intl/locale-data/de'
import * as fr from 'react-intl/locale-data/fr'
import * as it from 'react-intl/locale-data/it'
import {IntlProvider, addLocaleData} from 'react-intl'
import {getCurrentLanguageOrFallBackByPath} from 'common/Languages'
import {Branch, Meta, Translations, Client} from 'interfaces/Interfaces'
import {
  sessionBranch,
  getInventoryList,
  getInventoryById,
  getTranslationData,
  getSession,
} from 'frontend/ApiClient'
import CmsRouter, {RouterLocation, RouterLocationExtended} from 'control/CmsRouter'
import {supportedLanguagesRegExp} from 'common/Languages'
import {Routes} from 'frontend/Routes'
import {updateState, StateAction} from 'vo/RootStateReducer'
import RootState from 'vo/RootState'
import ModLogin from './login/ModLogin'
import ModFooter from './blocks/ModFooter'
import ModNavigation from './blocks/ModNavigation'
import ModInventoryList from './blocks/ModInventoryList'
import ModPrintView from './blocks/ModPrintView'
import ModInventoryContent from './blocks/ModInventoryContent'
import ModReports from './report/ModReports'
import ModReportPreview from './report/ModReportPreview'
import UserSession from 'vo/UserSession'
import {getBrowser, BrowserDetectionResponse, Browser} from 'common/BrowserDetection'
import ModFallbackPage from './blocks/ModFallbackPage'

addLocaleData([...de, ...fr, ...it])

export interface ModAppProps {
  location: RouterLocationExtended
  router: CmsRouter
  APP_PROPS?: RootState
}

const defaultContext: {
  location: RouterLocation
  router: CmsRouter
  currentLanguage: string
} = {
  router: null,
  location: {
    pathname: '',
    query: null,
  },
  currentLanguage: '',
}

export const NavigationNodeContext = React.createContext(defaultContext)

export default class ModApp extends React.Component<ModAppProps, RootState> {
  private browser: BrowserDetectionResponse

  constructor(props) {
    super(props)

    if (typeof window !== 'undefined') {
      this.browser = getBrowser()
      let rs = new RootState()
      if (window.hasOwnProperty('PUBLIC_CONFIG')) {
        rs.publicSettings = window['PUBLIC_CONFIG']
      }
      this.state = Object.assign(rs)
    } else if (this.props.APP_PROPS) {
      this.state = this.props.APP_PROPS
    }

    this.setStateAndCurrentLang = this.setStateAndCurrentLang.bind(this)
    this.hasUpdatedLocation = this.hasUpdatedLocation.bind(this)
    this.onCloseModalView = this.onCloseModalView.bind(this)
    this.setSession = this.setSession.bind(this)
    this.loadTranslations = this.loadTranslations.bind(this)
    this.setRootState = this.setRootState.bind(this)
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this)
  }

  static async loadInventoryItems(id) {
    return await getInventoryById(id)
  }

  static async loadInventoryList() {
    return await getInventoryList()
  }

  onCloseModalView() {
    this.props.router.push({
      hash: '',
      pathname: this.props.location.pathname,
      query: null,
    })
  }

  setStateAndCurrentLang(newState) {
    newState = Object.assign(JSON.parse(JSON.stringify(this.state)), newState)
    this.setState(newState)
  }

  async loadTranslations(): Promise<Translations> {
    return getTranslationData()
  }

  async setRootState(action: StateAction, payload?: any) {
    this.setState((prevState, props) => updateState(prevState, action, payload))
  }

  async setSession(userSession: Meta<Branch | Client>, passive: boolean = false) {
    const userSessionObj = new UserSession(userSession)
    this.setState({
      userSession: new UserSession(userSession),
      inventoryList: [],
      brokenSession: false,
    })

    // TODO we should do some refactring below
    if (!passive) {
      this.setState({
        context: null,
      })
    }

    if (!userSession) {
      return
    }

    if (!userSessionObj.isHeadOffice && !passive) {
      let inventoryList = await ModApp.loadInventoryList()
      await this.setRootState(StateAction.updateInventoryList, inventoryList)
    }
  }

  redirectAfterSessionStateChange(prevState: RootState) {
    const {location} = this.props
    const {userSession} = this.state

    if (userSession != prevState.userSession) {
      const currentLanguage = getCurrentLanguageOrFallBackByPath(this.props.location.pathname)
      const pathName = location.pathname.replace(supportedLanguagesRegExp, '')
      let redirectPath: string = null

      if (
        userSession.hasSession &&
        !userSession.isHeadOffice &&
        (!pathName || pathName === Routes.login || pathName === Routes.home)
      ) {
        redirectPath = `/${currentLanguage}${Routes.branch}`
      } else if (
        userSession.hasSession &&
        userSession.isHeadOffice &&
        (!pathName || pathName === Routes.login || pathName === Routes.home)
      ) {
        redirectPath = `/${currentLanguage}${Routes.client}`
      } else if (!userSession.hasSession) {
        redirectPath = `/${currentLanguage}${Routes.login}`
      }

      if (redirectPath) {
        this.props.router.push({
          hash: '',
          pathname: redirectPath,
          query: null,
        })
      }
    }
  }

  hasUpdatedLocation(lastProps, nextProps) {
    try {
      if (lastProps.location.pathname !== nextProps.location.pathname) {
        return true
      }
    } catch (e) {}
    return false
  }

  async componentDidMount() {
    const {router} = this.props

    this.loadTranslations()
      .then((translations: Translations) => {
        this.setState({
          translations: translations,
        })
      })
      .catch(console.error)

    getSession()
      .then((data: Meta<Client | Branch>) => {
        this.setSession(data)
      })
      .catch(() => {
        const currentLanguage = getCurrentLanguageOrFallBackByPath(this.props.location.pathname)
        router.push(`/${currentLanguage}/login`)
      })

    window.addEventListener('load', function () {
      let status = document.getElementById('status')

      function updateOnlineStatus(event) {
        let condition = !navigator.onLine ? 'offline' : ''

        status.className = condition
        status.innerHTML = condition.toUpperCase()
      }

      window.addEventListener('online', updateOnlineStatus)
      window.addEventListener('offline', updateOnlineStatus)
    })

    document.addEventListener('visibilitychange', this.handleVisibilityChange, false)

    const {userSession} = this.state
    if (!userSession.hasSession) {
      return
    }

    let inventoryList = await ModApp.loadInventoryList()
    await this.setRootState(StateAction.updateInventoryList, inventoryList)
    this.setRootState(StateAction.removeLoadingIndex)
  }

  handleVisibilityChange() {
    const {userSession} = this.state

    if (!document.hidden) {
      getSession()
        .then((result: Meta<Client | Branch>) => {
          if (!(userSession.hasSession && userSession.user.id === result.value.id)) {
            this.setState({
              brokenSession: true,
            })
          }
        })
        .catch((e) => {
          this.setState({
            brokenSession: true,
          })
        })
    }
  }

  async componentWillUpdate(nextProps: ModAppProps, nextState: RootState) {
    if (this.hasUpdatedLocation(this.props, nextProps)) {
      this.setRootState(StateAction.addLoadingIndex)
    }
  }

  async componentDidUpdate(prevProps: ModAppProps, prevState: RootState) {
    const {location} = this.props

    this.redirectAfterSessionStateChange(prevState)

    if (location.pathname !== prevProps.location.pathname || !this.state.context) {
      const pathName = location.pathname.replace(supportedLanguagesRegExp, '')
      const regex = /\/branch\/(.*?)(\/|$)/gi
      const id = regex.exec(pathName)

      if (id) {
        try {
          const rootContext = await ModApp.loadInventoryItems(id[1])
          await this.setRootState(StateAction.updateInventory, rootContext)
        } catch (error) {
          const currentLanguage = getCurrentLanguageOrFallBackByPath(this.props.location.pathname)
          this.setRootState(StateAction.removeLoadingIndex)
          this.props.router.push(`/${currentLanguage}/branch`)
          alert(error)
        }
      }
    }
  }

  render() {
    const {location} = this.props
    const {translations, inventoryList, userSession, brokenSession} = this.state
    const currentLanguage = getCurrentLanguageOrFallBackByPath(this.props.location.pathname)

    let content = (
      <div className="app-content">
        <ModNavigation
          userSession={this.state.userSession}
          languageVisible={this.state.languageVisible}
          setSession={this.setSession}
          location={location}
          currentLanguage={currentLanguage}
          loading={this.state.loadingIndex}
          setRootState={this.setRootState}
        />
        <div className="error">Error 404</div>
        <ModFooter />
        <div id="status" />
      </div>
    )
    if (!translations) {
      return (
        <div className="app-content">
          <div className="app-loader">
            <img className="cosanum-logo" src={require('static/img/cosanum.png')} />
            <div className="loader">
              <div />
              <div />
              <div />
              <div />
            </div>
          </div>
        </div>
      )
    }

    const pathName = location.pathname.replace(supportedLanguagesRegExp, '')

    if (this.browser.browser === Browser.ie) {
      content = <ModFallbackPage currentLanguage={currentLanguage} />
    } else if (
      !pathName ||
      pathName === Routes.login ||
      pathName === Routes.home ||
      !userSession.hasSession
    ) {
      content = (
        <div>
          <ModLogin setSession={this.setSession} location={location} />
        </div>
      )
    } else if (brokenSession && userSession.hasSession) {
      content = (
        <div>
          <ModLogin setSession={this.setSession} location={location} restoreSession={userSession} />
        </div>
      )
    } else if (pathName.startsWith(Routes.branch)) {
      let contentOfContent = (
        <ModInventoryList
          currentLanguage={currentLanguage}
          inventoryList={inventoryList}
          setRootState={this.setRootState}
        />
      )

      if (this.state.context != null) {
        if (pathName.includes(Routes.print)) {
          contentOfContent = (
            <ModPrintView
              currentLanguage={currentLanguage}
              context={this.state.context}
              setRootState={this.setRootState}
              searchInput={this.state.searchInput}
            />
          )
        } else {
          if (pathName !== Routes.branch) {
            contentOfContent = (
              <ModInventoryContent
                currentLanguage={currentLanguage}
                context={this.state.context}
                setRootState={this.setRootState}
                location={location}
                searchInput={this.state.searchInput}
              />
            )
          }
        }
      }

      content = (
        <div className="app-content">
          <ModNavigation
            userSession={this.state.userSession}
            languageVisible={this.state.languageVisible}
            setSession={this.setSession}
            location={location}
            currentLanguage={currentLanguage}
            loading={this.state.loadingIndex}
            setRootState={this.setRootState}
          />
          {contentOfContent}
          <ModFooter />
          <div id="status" />
        </div>
      )
    } else if (pathName.startsWith(Routes.client)) {
      let contentOfContent = (
        <ModReports
          userSession={userSession}
          currentLanguage={currentLanguage}
          setRootState={this.setRootState}
        />
      )

      if (pathName.includes(Routes.preview)) {
        contentOfContent = (
          <ModReportPreview
            userSession={userSession}
            branchReport={this.state.branchReport}
            currentLanguage={currentLanguage}
            setRootState={this.setRootState}
            reportType={this.state.reportType}
          />
        )
      }

      content = (
        <div className="app-content">
          <ModNavigation
            userSession={this.state.userSession}
            languageVisible={this.state.languageVisible}
            setSession={this.setSession}
            location={location}
            currentLanguage={currentLanguage}
            loading={this.state.loadingIndex}
            setRootState={this.setRootState}
          />
          {contentOfContent}
          <ModFooter />
          <div id="status" />
        </div>
      )
    }

    return (
      <IntlProvider
        locale={navigator.language}
        messages={translations.translations[currentLanguage]}>
        <NavigationNodeContext.Provider
          value={{
            location: this.props.location,
            router: this.props.router,
            currentLanguage: currentLanguage,
          }}>
          {content}
        </NavigationNodeContext.Provider>
      </IntlProvider>
    )
  }
}
