import * as React from 'react'
import {
  FormattedMessage,
  FormattedNumber,
  injectIntl,
  InjectedIntlProps,
  IntlProvider,
} from 'react-intl'
import {
  InventoryItem,
  InventoryItemData,
  RootState,
  PricedArticle,
  Manufacturer,
  Inventory,
} from 'interfaces/Interfaces'
import {getItemPrice} from 'common/Utils'

export interface ModProductItemProps extends InjectedIntlProps {
  id: number
  item: InventoryItem
  article: PricedArticle
  currentLanguage: string
  dimensionData: InventoryItemData
  manufacturer: Manufacturer
  inventory: Inventory
  handleQuantityChange: (inventoryIndex: number, dimensionIndex: number, event) => void
  intl: any
}

interface ModProductItemState {}

class ModProductItem extends React.Component<ModProductItemProps, ModProductItemState> {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const {formatMessage} = this.props.intl
    const {
      item,
      article,
      currentLanguage,
      dimensionData,
      manufacturer,
      inventory,
      id,
      handleQuantityChange,
    } = this.props

    return (
      <div className="product-content" key={item.articleId}>
        <div className="content-left">
          {article.imageUrl && <img className="product-image" src={article.imageUrl} />}
        </div>

        <div className="content-center-left">
          <div className="">
            <div className="title small-width">
              <FormattedMessage id="inventor.manufacturer" defaultMessage="manufacturer" />:
            </div>
            <div className="string">
              <strong>{manufacturer.name}</strong>
            </div>
          </div>

          <div className="">
            <div className="title small-width">
              <FormattedMessage id="inventor.articleId" defaultMessage="item no M" />:
            </div>
            <div className="string">
              <strong>{article.idManufacturer}</strong>
            </div>
          </div>

          <div className="">
            <div className="title small-width">
              <FormattedMessage id="inventor.idCosanum" defaultMessage="item no C" />:
            </div>
            <div className="string">
              <strong>{item.articleId}</strong>
            </div>
          </div>

          <div className="description">
            <div className="title small-width">
              <FormattedMessage id="inventor.description" defaultMessage="description" />:
            </div>
            <div className="string">
              <strong>
                {article.i18n[currentLanguage] == undefined
                  ? ''
                  : article.i18n[currentLanguage].description}{' '}
              </strong>
            </div>
          </div>
        </div>

        <div className="content-center-right">
          <div className="">
            <div className="title medium-width">
              <FormattedMessage id="inventor.price" defaultMessage="price" />:
            </div>
            <div className="string">
              <strong>
                <FormattedMessage id="inventor.per" defaultMessage="per" />{' '}
                {article.priceUnitQuantity} {article.itemUnit}
              </strong>
            </div>
          </div>

          <div className="">
            <div className="title medium-width">
              <FormattedMessage
                id="inventor.salesPrice"
                defaultMessage="Sales price per price unit"
              />
              :
            </div>
            <div className="string">
              <strong>
                <IntlProvider locale="de-CH">
                  <FormattedNumber
                    style="currency"
                    currency="CHF"
                    value={dimensionData.retailPrice}
                  />
                </IntlProvider>
              </strong>
            </div>
          </div>

          <br />

          <div className="">
            <div className="title medium-width">
              <FormattedMessage id="inventor.goodsValue" defaultMessage="goods value" />:
            </div>
            <div className="string">
              <strong>
                <IntlProvider locale="de-CH">
                  <FormattedNumber style="currency" currency="CHF" value={getItemPrice(item)} />
                </IntlProvider>
              </strong>
            </div>
          </div>
        </div>

        <div className="content-right">
          {dimensionData.dimensions.map((dimension, dimensionIndex) => {
            if (dimension.id != 'pal') {
              let inputField = null
              if (!inventory.completionDate) {
                inputField = (
                  <input
                    className="t-buy-quantity string"
                    type="number"
                    autoComplete="off"
                    name="name"
                    min="0"
                    onChange={(e) => handleQuantityChange(id, dimensionIndex, e)}
                    value={dimension.stock || ''}
                  />
                )
              } else {
                inputField = (
                  <input
                    className="t-buy-quantity string"
                    type="number"
                    autoComplete="off"
                    name="name"
                    min="0"
                    readOnly
                    value={dimension.stock || ''}
                  />
                )
              }

              return (
                <div key={dimensionIndex}>
                  <div className="title small-width">
                    <IntlProvider locale="de-CH">
                      <FormattedNumber
                        style="currency"
                        currency="CHF"
                        value={
                          (dimensionData.retailPrice / dimensionData.priceUnitQuantity) *
                          dimension.quantity
                        }
                      />
                    </IntlProvider>
                    /
                    <strong>
                      {formatMessage({
                        id: 'inventor.' + dimension.id,
                        defaultMessage: dimension.description,
                      })}
                    </strong>
                  </div>
                  {inputField}
                </div>
              )
            }
          })}
        </div>
      </div>
    )
  }
}

export default injectIntl<ModProductItemProps>(ModProductItem)
