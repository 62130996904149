import * as React from 'react'
import {FormattedMessage} from 'react-intl'

export interface ModFooterProps {}

export default class ModFooter extends React.Component<ModFooterProps, {}> {
  render() {
    return (
      <footer className="footer">
        <div className="footer-content">
          <div className="footer-content-left">
            <strong>
              <FormattedMessage id="footer.technicalSupport" defaultMessage="Technical Support " />
            </strong>
            <p>
              <FormattedMessage id="footer.technicalSuppoertText" defaultMessage=" " />
            </p>
          </div>

          {/* <div className="footer-logo">
            <img src={require('static/img/footer-logo.png')} />
          </div> */}

          <div className="footer-content-right">
            <strong>
              <FormattedMessage
                id="footer.customerService"
                defaultMessage="Happy Customer Service Heim"
              />
            </strong>
            <p>
              <FormattedMessage id="footer.customerServiceText" defaultMessage=" " />
            </p>
          </div>
        </div>
      </footer>
    )
  }
}
