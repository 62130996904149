export const IsomorphicURL = (() => {
  if (typeof document === 'undefined') {
    return require('url').URL
  } else {
    return URL
  }
})()

export const IsomorphicURLSearchParams = (() => {
  if (typeof document === 'undefined') {
    return require('url').URLSearchParams
  } else {
    return URLSearchParams
  }
})()
