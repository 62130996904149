import * as React from 'react'
import {BranchReport} from 'interfaces/Interfaces'

export interface MadAddressReportProps {
  branchReport: BranchReport
}

export default class ReportTableView extends React.Component<MadAddressReportProps, {}> {
  render() {
    const {branchReport} = this.props

    let header = null
    let branchItems = Object.entries(branchReport).map((entry, index) => {
      const [key, val] = entry

      if (index === 0) {
        header = (
          <tr className="branch-header">
            {Object.keys(val).map((k) => (
              <th>
                <strong>{k}</strong>
              </th>
            ))}
          </tr>
        )
      }

      const row = Object.entries(val).map((e) => {
        const [key, val] = e
        return <td key={key}>{val}</td>
      })
      return <tr key={key}>{row}</tr>
    })

    return (
      <div className="table-wrapper">
        <table className="branch-content address">
          {header}
          {branchItems}
        </table>
      </div>
    )
  }
}
