import RootState from './RootState'

export enum StateAction {
  updateInventory,
  updateInventoryList,
  addLoadingIndex,
  removeLoadingIndex,
  updateBranchReport,
  reportType,
  languageVisible,
  searchInput,
}

export function updateState(currentState: RootState, action: StateAction, payload: any) {
  currentState = Object.assign(new RootState(), currentState)
  switch (action) {
    case StateAction.updateInventory:
      currentState.context = payload
      break
    case StateAction.updateInventoryList:
      currentState.inventoryList = payload
      break
    case StateAction.addLoadingIndex:
      currentState.loadingIndex += 1
      break
    case StateAction.removeLoadingIndex:
      if (currentState.loadingIndex != 0) {
        currentState.loadingIndex = 0
      }
      break
    case StateAction.updateBranchReport:
      currentState.branchReport = payload
      break
    case StateAction.reportType:
      currentState.reportType = payload
      break
    case StateAction.languageVisible:
      currentState.languageVisible = payload
      break
    case StateAction.searchInput:
      currentState.searchInput = payload
      break
  }

  return currentState
}
