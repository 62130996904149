import * as React from 'react'
import {FormattedMessage, injectIntl, InjectedIntlProps} from 'react-intl'
import {Branch, Meta, Client} from 'interfaces/Interfaces'
import {logoutBranch, logoutClient} from 'frontend/ApiClient'
import {RouterLocationExtended} from 'control/CmsRouter'
import {supportedLanguages, supportedLanguagesRegExp} from 'common/Languages'
import NavLink from '../common/NavLink'
import {StateAction} from 'vo/RootStateReducer'
import {Routes} from 'frontend/Routes'
import UserSession from 'vo/UserSession'

export interface ModNavigationProps extends InjectedIntlProps {
  languageVisible: boolean
  userSession: UserSession
  setSession: (userSession: Meta<Branch | Client>) => void
  location: RouterLocationExtended
  intl: any
  currentLanguage: string
  loading: number
  setRootState: (action: StateAction, payload?: any) => void
}

function deleteAllCookies() {
  var cookies = document.cookie.split(';')

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i]
    var eqPos = cookie.indexOf('=')
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
  }
}

class ModNavigation extends React.Component<ModNavigationProps, {}> {
  constructor(props) {
    super(props)
    this.onLogout = this.onLogout.bind(this)
  }

  onLogout() {
    const {userSession} = this.props
    if (userSession.hasSession) {
      if (userSession.isHeadOffice) {
        logoutClient()
          .then(() => {
            deleteAllCookies()
            this.props.setSession(null)
          })
          .catch(console.error)
      } else {
        logoutBranch()
          .then(() => {
            deleteAllCookies()
            this.props.setSession(null)
          })
          .catch(console.error)
      }
    }
  }

  async componentWillUpdate(prevProps: ModNavigationProps, prevState: {}) {
    if (prevProps.currentLanguage !== this.props.currentLanguage) {
      await this.props.setRootState(StateAction.removeLoadingIndex)
    }
  }

  render() {
    const {location, currentLanguage, loading, languageVisible, userSession} = this.props
    const {formatMessage} = this.props.intl

    const length = supportedLanguages.length
    let lang = supportedLanguages.map((lang, index) => {
      if (length != index + 1) {
        return (
          <div className="language" key={index}>
            <NavLink to={location.pathname.replace(supportedLanguagesRegExp, `/${lang}`)}>
              <p className="desktop">
                {formatMessage({id: 'language.' + lang, defaultMessage: lang})}
              </p>
              <p className="mobile">{lang}</p>
            </NavLink>
            <div className="slash">/</div>
          </div>
        )
      } else {
        return (
          <div key={index}>
            <NavLink to={location.pathname.replace(supportedLanguagesRegExp, `/${lang}`)}>
              <p className="desktop">
                {formatMessage({id: 'language.' + lang, defaultMessage: lang})}
              </p>
              <p className="mobile">{lang}</p>
            </NavLink>
          </div>
        )
      }
    })

    let loader = null
    if (loading >= 1) {
      loader = (
        <div className="loader">
          <div />
          <div />
          <div />
          <div />
        </div>
      )
    }

    return (
      <div className="navigation">
        <div className="navigation-content">
          <div className={languageVisible ? 'language' : 'language hidden'}>{lang}</div>

          <div className="navigation-logo">
            <a
              href={
                userSession.isHeadOffice
                  ? '/' + currentLanguage + Routes.client
                  : '/' + currentLanguage + Routes.branch
              }>
              <img src={require('static/img/cosanum-navigation.png')} />
            </a>
          </div>

          <div className="logout" onClick={this.onLogout}>
            {loader}
            <div className="desktop">
              <img src={require('static/img/svg/user.svg')} />
              <FormattedMessage id="navigation.signOut" defaultMessage="sign out" />
            </div>

            <div className="mobile">
              <img src={require('static/img/svg/logout.svg')} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl<ModNavigationProps>(ModNavigation)
