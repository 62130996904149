import * as React from 'react'
import {injectIntl, InjectedIntlProps, FormattedMessage} from 'react-intl'

export interface ModPaginationProps extends InjectedIntlProps {
  pages: number
  currentPage: number
  toPage: (indes: number) => void
}

class ModPagination extends React.Component<ModPaginationProps, {}> {
  // changePage(event) {
  //   let nextPage: number = event.target.value

  //   if (nextPage < 0) {
  //     nextPage = 0
  //   }

  //   if (nextPage > this.props.pages) {
  //     nextPage = this.props.pages
  //   }

  //   this.props.toPage(nextPage)
  // }

  render() {
    const {pages, currentPage, toPage} = this.props

    let previousPage = currentPage - 1
    let nextPage = currentPage + 1

    let paginationController = (
      <div className="pagination-controller">
        <div
          className={currentPage <= 0 ? 'content-left hidden' : 'content-left'}
          onClick={() => toPage(previousPage)}>
          <img src={require('static/img/svg/arrow-left.svg')} />
        </div>

        <div className="center">
          <FormattedMessage id="pagination.page" defaultMessage="Page" />
          {/* <input min={1} max={this.props.pages} onChange={event => this.changePage(event)} value={this.props.currentPage} /> */}
          <div>
            <strong>{currentPage + 1}</strong>
          </div>
          <div className="space">/</div>
          <div>{pages}</div>
        </div>

        <div
          className={currentPage >= pages - 1 ? 'content-right hidden' : 'content-right'}
          onClick={() => toPage(nextPage)}>
          <img src={require('static/img/svg/arrow-right.svg')} />
        </div>
      </div>
    )

    return <div className="pagination">{paginationController}</div>
  }
}

export default injectIntl<ModPaginationProps>(ModPagination)
