export const supportedLanguages = ['de', 'fr', 'it']
export const fallBackLanguage = 'de'
export const supportedLanguagesRegExp = new RegExp(`^\/(${supportedLanguages.join('|')})`)

export function getCurrentLanguageByPath(path: string): string {
  const regex = new RegExp(`^\/?(${supportedLanguages.join('|')})`, 'i')
  const match = regex.exec(path)
  if (!(match && match.length > 0)) {
    return 'unknown'
  }
  return match[1]
}

export function getCurrentLanguageOrFallBackByPath(path: string): string {
  let currentLanguage = getCurrentLanguageByPath(path)
  return currentLanguage === 'unknown' ? fallBackLanguage : currentLanguage
}
