import {Branch, Meta, Client} from '../interfaces/Interfaces'

export default class UserSession {
  private _user?: Meta<Branch | Client>

  constructor(_user: Meta<Branch | Client> = null) {
    this._user = _user
  }

  get user() {
    if (!this._user) return null
    return this._user.value
  }

  get hasSession() {
    return !!this._user
  }

  get isHeadOffice(): boolean {
    if (!this._user) return false
    return !(this._user.value as Branch).hasOwnProperty('plz')
  }
}
