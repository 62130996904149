import * as React from 'react'
import ModProducts from './ModProducts'
import ModConsumer from './ModConsumer'
import {InventorySearchInput, RootState} from 'interfaces/Interfaces'
import {RouterLocationExtended} from 'control/CmsRouter'
import {StateAction} from 'vo/RootStateReducer'

export interface ModInventoryContentProps {
  context: RootState
  currentLanguage: string
  location: RouterLocationExtended
  searchInput: InventorySearchInput
  setRootState: (action: StateAction, payload?: any) => void
}

export default class ModInventoryContent extends React.Component<ModInventoryContentProps, {}> {
  render() {
    const {context, currentLanguage, setRootState, location, searchInput} = this.props

    return (
      <div className="inventory-wrapper">
        <ModConsumer
          currentLanguage={currentLanguage}
          context={context}
          setRootState={setRootState}
          location={location}
        />

        <ModProducts
          currentLanguage={currentLanguage}
          context={context}
          setRootState={setRootState}
          location={location}
          searchInput={searchInput}
        />
      </div>
    )
  }
}
