import * as React from 'react'

export interface ModFallbackPageProps {
  currentLanguage: string
}

export default class ModFallbackPage extends React.Component<ModFallbackPageProps, {}> {
  render() {
    const {currentLanguage} = this.props
    let content = null
    if (currentLanguage === 'de') {
      content = (
        <div>
          <p>
            Bitte benutzen sie ein aktueller Browser wie "Mozilla Firefox" oder "Google Chrome"
            welcher auf ihrem PC installiert sein dürfte.
          </p>
          <br />
          <p>
            Anbei die Download Links falls noch kein aktueller Browser auf Ihrem System installiert
            ist.
          </p>
          <br />
          <p>
            <a href="https://www.google.com/chrome/">Google Chrome</a>
          </p>
          <p>
            <a href="https://www.mozilla.org">Mozilla Firefox</a>
          </p>
        </div>
      )
    }
    if (currentLanguage === 'fr') {
      content = (
        <div>
          <p>
            Veuillez utiliser un navigateur actuel comme "Mozilla Firefox" ou "Google Chrome" qui
            doit être installé sur votre PC.
          </p>
          <br />
          <p>
            Vous trouverez ci-joint les liens de téléchargement si aucun navigateur actuel n'est
            installé sur votre système.
          </p>
          <br />
          <p>
            <a href="https://www.google.com/chrome/">Google Chrome</a>
          </p>
          <p>
            <a href="https://www.mozilla.org">Mozilla Firefox</a>
          </p>
        </div>
      )
    }
    if (currentLanguage === 'it') {
      content = (
        <div>
          <p>
            Si prega di utilizzare un browser corrente come "Mozilla Firefox" o "Google Chrome" che
            dovrebbe essere installato sul vostro PC.
          </p>
          <br />
          <p>
            In allegato ci sono i link per il download se sul sistema non è installato un browser
            corrente.
          </p>
          <br />
          <p>
            <a href="https://www.google.com/chrome/">Google Chrome</a>
          </p>
          <p>
            <a href="https://www.mozilla.org">Mozilla Firefox</a>
          </p>
        </div>
      )
    }

    return (
      <div className="login-wrapper">
        <div className="login-content-right" />
        <div className="login-content-left">
          <div className="login-header">
            <img className="cosanum-logo" src={require('static/img/cosanum.png')} />
          </div>

          <div className="fallback-page">{content}</div>
        </div>
      </div>
    )
  }
}
