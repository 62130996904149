import * as fetch from 'isomorphic-fetch'
import {
  Availableinventories,
  Branch,
  BranchReport,
  Inventory,
  Meta,
  RootState,
} from '../interfaces/Interfaces'
import {ApiResponse} from '../backend/common/BackendUtils'
import {PublicError} from '../backend/common/PublicError'

let host: string = process.env.API_HOST || ''
let sessionCookieForTesting = null

export async function getTranslationData() {
  return basicFetch('/api/base/translations', get())
}

export async function loginClient(id: string, password: string): Promise<Meta<Branch>> {
  return basicFetch('/api/client/login', post({id, password}))
}

export async function logoutClient() {
  return basicFetch('/api/client/logout', get())
}

export async function getSession() {
  return basicFetch('/api/auth/session', get())
}

export async function sessionClient() {
  return basicFetch('/api/client/session', get())
}

export async function loginBranch(id: string, password: string): Promise<Meta<Branch>> {
  return basicFetch('/api/branch/login', post({id, password}))
}

export async function logoutBranch() {
  return basicFetch('/api/branch/logout', get())
}

export async function sessionBranch() {
  return basicFetch('/api/branch/session', get())
}

export async function getInventoryList(): Promise<Inventory[]> {
  return basicFetch(`/api/branch/inventory`, get())
}

export async function getInventoryById(id: string): Promise<RootState> {
  return basicFetch(`/api/branch/inventory/${id}`, get())
}

export async function updateInventoryStock(inventory: Inventory): Promise<Meta<Inventory>> {
  return basicFetch(`/api/branch/inventory/${inventory.id}`, put(inventory))
}

export async function activateInventoryOfCurrentQuarter(): Promise<Meta<Inventory>> {
  return basicFetch(`/api/branch/inventory/activate-current-quarter`, get())
}

export async function closeInventory(id: string): Promise<Meta<Inventory>> {
  return basicFetch(`/api/branch/inventory/${id}/close`, put())
}

export async function reOpenInventory(id: string): Promise<Meta<Inventory>> {
  return basicFetch(`/api/branch/inventory/${id}/re-open`, put())
}

export async function getBranchReportDetail(id: string, lang: string): Promise<BranchReport[]> {
  return basicFetch(`/api/branch/inventory/${id}/report/detail?lang=${lang}`, get())
}

export function getBranchReportDetailByFormat(id: string, lang: string, format: string): string {
  return `/api/branch/inventory/${id}/report/detail/${format}?lang=${lang}`
}

export async function getBranchReportApplication(
  id: string,
  lang: string
): Promise<BranchReport[]> {
  return basicFetch(`/api/branch/inventory/${id}/report/application?lang=${lang}`, get())
}

export function getBranchReportApplicationByFormat(
  id: string,
  lang: string,
  format: string
): string {
  return `/api/branch/inventory/${id}/report/application/${format}?lang=${lang}`
}

export async function getAvailableClientReports(): Promise<Availableinventories[]> {
  return basicFetch(`/api/client/availableinventories`, get())
}

export async function getClientReportDetail(
  lang: string,
  quartersSinceYear0: number
): Promise<BranchReport[]> {
  return basicFetch(
    `/api/client/report/detail?lang=${lang}&quartersSinceYear0=${quartersSinceYear0}`,
    get()
  )
}

export function getClientReportDetailByFormat(
  lang: string,
  quartersSinceYear0: number,
  format: string
): string {
  return `/api/client/report/detail/${format}?lang=${lang}&quartersSinceYear0=${quartersSinceYear0}`
}

export async function getClientReportClosed(
  lang: string,
  quartersSinceYear0: number
): Promise<BranchReport[]> {
  return basicFetch(
    `/api/client/report/closed?lang=${lang}&quartersSinceYear0=${quartersSinceYear0}`,
    get()
  )
}

export function getClientReportClosedByFormat(
  lang: string,
  quartersSinceYear0: number,
  format: string
): string {
  return `/api/client/report/closed/${format}?lang=${lang}&quartersSinceYear0=${quartersSinceYear0}`
}

export async function getClientReportApplication(
  lang: string,
  quartersSinceYear0: number
): Promise<BranchReport[]> {
  return basicFetch(
    `/api/client/report/application?lang=${lang}&quartersSinceYear0=${quartersSinceYear0}`,
    get()
  )
}

export function getClientReportApplicationByFormat(
  lang: string,
  quartersSinceYear0: number,
  format: string
): string {
  return `/api/client/report/application/${format}?lang=${lang}&quartersSinceYear0=${quartersSinceYear0}`
}

export async function getClientReportMigel(
  lang: string,
  quartersSinceYear0: number
): Promise<BranchReport[]> {
  return basicFetch(
    `/api/client/report/migel?lang=${lang}&quartersSinceYear0=${quartersSinceYear0}`,
    get()
  )
}

export function getClientReportMigelByFormat(
  lang: string,
  quartersSinceYear0: number,
  format: string
): string {
  return `/api/client/report/migel/${format}?lang=${lang}&quartersSinceYear0=${quartersSinceYear0}`
}

export async function basicFetch(url: Request | string, options?: RequestInit) {
  const response = await fetch(host + url, options)
  await handleStatus(response)

  const setCookie = response.headers.get('set-cookie')
  if (typeof window === 'undefined' && setCookie) {
    sessionCookieForTesting = setCookie.substr(0, setCookie.indexOf(';'))
  }
  return handleResponse(await response.json())
}

export async function handleStatus(response: Response) {
  console.log('response.status', response.status)
  if (response.status === 500) {
    throw new Error(response.statusText)
  }
}

export async function handleResponse(json: ApiResponse) {
  if (json.error) {
    if (json.error.reason) {
      throw new PublicError(json.error.message, json.error.reason)
    } else {
      throw new Error(json.error.message)
    }
  } else {
    return json.response
  }
}

function createHeaders(): Headers {
  let headers = new Headers()
  headers.append('Content-Type', 'application/json')
  if (sessionCookieForTesting) {
    headers.append('Cookie', sessionCookieForTesting)
  }
  return headers
}

function get(): RequestInit {
  return {
    method: 'GET',
    headers: createHeaders(),
    credentials: 'same-origin',
  }
}

function post(payload?: any): RequestInit {
  return {
    method: 'POST',
    headers: createHeaders(),
    credentials: 'same-origin',
    body: payload ? JSON.stringify(payload) : '',
  }
}

function put(payload?: any): RequestInit {
  return {
    method: 'PUT',
    headers: createHeaders(),
    credentials: 'same-origin',
    body: payload ? JSON.stringify(payload) : '',
  }
}
