import * as React from 'react'
import {FormattedMessage, injectIntl, InjectedIntlProps} from 'react-intl'
import NavLink from '../common/NavLink'
import {Routes} from 'frontend/Routes'
import {Inventory, InventoryList} from 'interfaces/Interfaces'
import {getQuarter} from 'common/Utils'
import {StateAction} from 'vo/RootStateReducer'

export interface ModInventoryListProps extends InjectedIntlProps {
  inventoryList: InventoryList[]
  currentLanguage: string
  setRootState: (action: StateAction, payload?: any) => void
  intl: any
}

export interface ModInventoryListStage {}

class ModInventoryList extends React.Component<ModInventoryListProps, ModInventoryListStage> {
  constructor(props) {
    super(props)

    this.state = {}
  }

  async componentDidMount() {
    await this.props.setRootState(StateAction.removeLoadingIndex)

    this.props.setRootState(StateAction.searchInput, {
      filteredItems: [],
      isFiltered: false,
      nameSearchInput: '',
      idCosanumSearchInput: '',
      nameProducerSearchInput: '',
      scopeSearch: '',
    })
  }

  render() {
    const {inventoryList} = this.props

    let date = new Date()
    let quarter = getQuarter(date)
    let year = date.getFullYear()

    let inventoryItems = null

    if (inventoryList != null) {
      inventoryItems = inventoryList
        .sort((a, b) => {
          return b.quartersSinceYear0 - a.quartersSinceYear0
        })
        .map((item: Inventory, index: number) => {
          if (item.activationDate) {
            let iventoryIcon = null
            if (item.completionDate) {
              iventoryIcon = <img src={require('static/img/svg/closedInventory.svg')} />
            } else {
              iventoryIcon = <img src={require('static/img/svg/openInventory.svg')} />
            }
            return (
              <div className="inventory-list-item" key={index}>
                <FormattedMessage id="inventory.itemTitle" defaultMessage="date period" />:
                <NavLink to={`${this.props.currentLanguage}${Routes.branch}/${item.id}`}>
                  <FormattedMessage id="inventory.year" defaultMessage="Year" /> {item.year} /{' '}
                  <FormattedMessage id="inventory.quarter" defaultMessage="Quarter" />{' '}
                  {item.quarter}
                  {iventoryIcon}
                </NavLink>
              </div>
            )
          }
        })
    }

    return (
      <div className="inventory-list-wrapper">
        <div className="title">
          <FormattedMessage
            id="inventory.Title"
            defaultMessage="Inventory lists by year / quarter"
          />
        </div>
        <div className="description">
          <FormattedMessage
            id="inventory.Description1"
            defaultMessage={`Bitte wählen sie das aktuellste Quartal aus.`}
          />
          <br></br>
          <FormattedMessage
            id="inventory.Description2"
            defaultMessage={`Die neue Inventurperiode wird jeweils am 15. des nächsten Monates im neuen Quartal automatisch eröffnet.`}
          />
        </div>
        <div className="inventory-list">{inventoryItems}</div>
      </div>
    )
  }
}

export default injectIntl(ModInventoryList)
