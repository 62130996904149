import {ApiResponseError} from './BackendUtils'

export class PublicError extends Error implements ApiResponseError {
  type = 'PublicError'
  reason

  constructor(message: string, reason: any = null) {
    super(message)
    this.reason = reason
  }
}
