import 'babel-polyfill'
import * as React from 'react'
import {render} from 'react-dom'
import * as Sentry from '@sentry/browser'
import App from './App'

require('intersection-observer')

declare var DEVELOPMENT: string
if (typeof DEVELOPMENT !== 'undefined' && DEVELOPMENT === 'development') {
  require('../static/scss/index.scss')
} else {
  if (Sentry) {
    Sentry.init({dsn: 'https://129a572fa3d241f99634487a919f4c68@sentry.io/1331716'})
  }
}

render(<App />, document.getElementById('app-wrapper'))
