import * as React from 'react'
import {FormattedMessage, injectIntl, InjectedIntlProps} from 'react-intl'
import {loginBranch, loginClient} from '../../frontend/ApiClient'
import {Branch, Meta, Client} from '../../interfaces/Interfaces'
import {RouterLocationExtended} from '../../control/CmsRouter'
import {supportedLanguages, supportedLanguagesRegExp} from '../../common/Languages'
import NavLink from '../common/NavLink'
import {StateAction} from 'vo/RootStateReducer'
import UserSession from 'vo/UserSession'

export interface ModLoginProps extends InjectedIntlProps {
  setSession: (branch: Meta<Branch | Client>, passive: boolean) => void
  location: RouterLocationExtended
  restoreSession?: UserSession
}

export interface ModLoginState {
  [key: string]: any

  user: string
  password: string
  pending: boolean
  error: any
  help: boolean
  headOffice: boolean
}

declare var DEV_INVENTORY_ADDRESS_USER: string
declare var DEV_INVENTORY_ADDRESS_PWD: string
declare var DEV_INVENTORY_CONDITION_USER: string
declare var DEV_INVENTORY_CONDITION_PWD: string

const headOfficeCheckboxName = 'headOffice'

class ModLogin extends React.Component<ModLoginProps, ModLoginState> {
  constructor(props) {
    super(props)

    let user = ''
    let pwd = ''
    if (typeof DEV_INVENTORY_ADDRESS_USER !== 'undefined') {
      user = DEV_INVENTORY_ADDRESS_USER
      pwd = DEV_INVENTORY_ADDRESS_PWD
    }

    this.state = {
      user: user,
      password: pwd,
      pending: false,
      error: null,
      help: false,
      headOffice: false,
    }

    this.onInputChange = this.onInputChange.bind(this)
    this.onLogin = this.onLogin.bind(this)
    this.displayHelpText = this.displayHelpText.bind(this)
    this.closeHelpText = this.closeHelpText.bind(this)
  }

  onInputChange(event) {
    const target: any = event.currentTarget
    const value = target.type === 'checkbox' ? target.checked : target.value

    if (target.name === headOfficeCheckboxName) {
      if (target.checked) {
        if (
          typeof DEV_INVENTORY_CONDITION_USER !== 'undefined' &&
          typeof DEV_INVENTORY_CONDITION_PWD !== 'undefined'
        ) {
          this.setState({
            user: DEV_INVENTORY_CONDITION_USER,
            password: DEV_INVENTORY_CONDITION_PWD,
          })
        }
      } else {
        if (
          typeof DEV_INVENTORY_ADDRESS_USER !== 'undefined' &&
          typeof DEV_INVENTORY_ADDRESS_PWD !== 'undefined'
        ) {
          this.setState({
            user: DEV_INVENTORY_ADDRESS_USER,
            password: DEV_INVENTORY_ADDRESS_PWD,
          })
        }
      }
    }

    this.setState({[target.name]: value})
  }

  onLogin(event) {
    event.preventDefault()

    const {restoreSession} = this.props
    let signInMethod = this.state.headOffice ? loginClient : loginBranch

    let user = this.state.user
    if (restoreSession) {
      signInMethod = restoreSession.isHeadOffice ? loginClient : loginBranch
      user = restoreSession.user.id
    }

    signInMethod(user, this.state.password)
      .then((response: Meta<Branch | Client>) => {
        if (!this.props.setSession) {
          return
        }
        this.setState({
          error: null,
        })
        this.props.setSession(response, !!restoreSession)
      })
      .catch((e) => {
        this.setState({
          error: <FormattedMessage id="login.error" defaultMessage="error" />,
        })
      })
  }

  displayHelpText() {
    this.setState({
      help: true,
    })
  }

  closeHelpText() {
    this.setState({
      help: false,
    })
  }

  render() {
    const {formatMessage} = this.props.intl
    const {help} = this.state
    const {restoreSession} = this.props

    let errorMessage = this.state.error ? <p className="error-message">{this.state.error}</p> : null

    const length = supportedLanguages.length
    let lang = supportedLanguages.map((lang, index) => {
      if (length != index + 1) {
        return (
          <div className="language" key={index}>
            <NavLink to={location.pathname.replace(supportedLanguagesRegExp, `/${lang}`)}>
              <p className="desktop">
                {formatMessage({id: 'language.' + lang, defaultMessage: lang})}
              </p>
              <p className="mobile">{lang}</p>
            </NavLink>
            <div className="slash">/</div>
          </div>
        )
      } else {
        return (
          <div className="language" key={index}>
            <NavLink to={location.pathname.replace(supportedLanguagesRegExp, `/${lang}`)}>
              <p className="desktop">
                {formatMessage({id: 'language.' + lang, defaultMessage: lang})}
              </p>
              <p className="mobile">{lang}</p>
            </NavLink>
          </div>
        )
      }
    })

    let loginHelpContent = null
    if (help) {
      loginHelpContent = (
        <div className="login-help-wrapper">
          <div className="login-help-content">
            <div className="close-help" onClick={this.closeHelpText}>
              x
            </div>
            <h1>
              <FormattedMessage id="login.helpTitle" defaultMessage="Login Error?" />
            </h1>
            <p>
              <FormattedMessage id="login.helpText" defaultMessage="Send a email to" />
              <br />
              <a
                href={
                  'mailto:' +
                  formatMessage({id: 'login.helpEmail', defaultMessage: 'samira.duddek@cosanum.ch'})
                }>
                <FormattedMessage id="login.helpEmail" defaultMessage="samira.duddek@cosanum.ch" />
              </a>
              <br />
              <br />
              <FormattedMessage
                id="login.helpReply"
                defaultMessage="We will contact you immediately."
              />
            </p>
          </div>
        </div>
      )
    }

    let text = null
    let user = this.state.user
    let checkbox = null
    if (restoreSession) {
      text = (
        <FormattedMessage
          id="login.textSessionRestore"
          defaultMessage="Session invalid. Please enter your Password"
        />
      )
      user = restoreSession.user.id
    } else {
      text = <FormattedMessage id="login.text" defaultMessage="Please enter your ID and Password" />
      checkbox = (
        <label className="head-office control control-checkbox">
          <input
            type="checkbox"
            name={headOfficeCheckboxName}
            value="value"
            onChange={this.onInputChange}
            checked={this.state.headOffice}
          />
          <div className="control_indicator" />
          Head Office
        </label>
      )
    }

    return (
      <div className="login-wrapper">
        <div className="login-content-right" />
        <div className="login-content-left">
          <div className="login-header">
            <div className="language">{lang}</div>
            <img className="cosanum-logo" src={require('static/img/cosanum.png')} />
          </div>

          <div className="login-content">
            <h1>
              <FormattedMessage id="login.title" defaultMessage="login" />
            </h1>
            <p>{text}</p>

            <div id="user-login">
              <form onSubmit={this.onLogin}>
                <input
                  className={this.state.error ? 'error-input' : ''}
                  type="text"
                  name="user"
                  placeholder={formatMessage({id: 'login.username', defaultMessage: 'username'})}
                  value={user}
                  onChange={this.onInputChange}
                  readOnly={!!restoreSession}
                  required
                />
                <input
                  className={this.state.error ? 'error-input error-input-last' : 'error-input-last'}
                  type="password"
                  name="password"
                  placeholder={formatMessage({id: 'login.password', defaultMessage: 'password'})}
                  value={this.state.password}
                  onChange={this.onInputChange}
                  required
                />

                {checkbox}

                <div className="error-wrapper">{errorMessage}</div>

                <div className="login-help">
                  <div onClick={this.displayHelpText}>
                    <FormattedMessage id="login.help" defaultMessage="help" />
                  </div>
                </div>

                <button className="button-general button-general-strong">
                  <FormattedMessage id="login.signIn" defaultMessage="sign in" />
                </button>
              </form>
            </div>

            {loginHelpContent}
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl<ModLoginProps>(ModLogin)
